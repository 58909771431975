@media (max-width: 687px) {
  .nav-item {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .nav-tabs .nav-link {
    border: none;
    border-top-right-radius: none;
  }
  #nav-home {
    justify-content: flex-start;
    align-content: center;
  }
}

#nav-icon {
  border: none;
  background-color: antiquewhite;
}

.primary-blue {
  color: #00458b;
}
.light-blue {
  color: #99ddff;
}

.off-blue {
  color: #3fd2c7;
}

/* ____________________________________________________________________________________________ */
/* overwritten bootstrap classes*/

.nav-item > a {
  float: none;
  padding: 10px 15px 10px;
  color: #3fd2c7;
  text-decoration: none;
  text-shadow: none;
  font-size: 1.2em;
  font-weight: 500;
}

a.nav-item:hover,
a.nav-item:focus {
  background-color: #3fd2c7;
  color: #00458b;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #00458b;
  /* background-color: #99ddff; */
}
