.center {
  display: flex;
  padding: 10px;
  color: green;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.img-thumb {
  width: 150px;
  height: auto;
}
